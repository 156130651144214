<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h1 class='display-2'>Monitoring Aktivitas</h1>
          </template>
          <v-toolbar flat>
            <div style="max-width:400px">
              <v-autocomplete v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            </div>
            <div style="max-width:400px" class="mx-5">
              <v-select v-model="selectBulan" :items="bulanList" label="Bulan" item-text="b" item-value="k" hide-details></v-select>
            </div>
            <div style="max-width:400px" class="mx-5">
              <v-text-field v-model="search" hide-details label="Cari NIP" append-icon="mdi-magnify"></v-text-field>
            </div>
            <div style="max-width:400px">
              <v-btn medium color="primary" @click="exportExcel()"><v-icon small>mdi-file-excel</v-icon> &nbsp;Export to Excel</v-btn>
            </div>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[5,10,15]}"
            hide-default-header
            class="pegawai-table"
            :loading="loading"
          >
            <template v-slot:header>
              <thead class="blue">
                <tr>
                  <th class="font-weight-light white--text" rowspan="2" width="18%">Nama-Nip</th>
                  <th class="font-weight-light white--text" rowspan="2">Jabatan</th>
                  <th class="font-weight-light white--text" rowspan="2">Satuan Kerja</th>
                  <th class="font-weight-light white--text" rowspan="2">Jumlah Aktivitas</th>
                  <th class="font-weight-light white--text" colspan="2">Aktivitas Utama</th>
                  <th class="font-weight-light white--text" colspan="2">Aktivitas Tambahan</th>
                  <th class="font-weight-light white--text" colspan="2">Review</th>
                </tr>
                <tr>
                  <th class="font-weight-light white--text">Jumlah Aktivitas</th>
                  <th class="font-weight-light white--text">Total Menit</th>
                  <th class="font-weight-light white--text">Jumlah Aktivitas</th>
                  <th class="font-weight-light white--text">Total Menit</th>
                  <th class="font-weight-light white--text">Jumlah Sudah Review</th>
                  <th class="font-weight-light white--text">Jumlah Review Seharusnya</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.nama="{item}">
              <!-- <a v-bind:href="baseUrl+'/admin/monitoring-aktivitas/pegawai?nip='+item.peg_nip+'&bulan='+selectBulan" target="blank"><strong>{{ item.peg_nama }}</strong></a><br>{{ item.peg_nip }} -->
              <!-- <br><br> -->
              <a @click="checkPegawai(item)" target="blank"><strong>{{ item.peg_nama }}</strong></a><br>{{ item.peg_nip }}
            </template>
            <template v-slot:item.jumlah_aktivitas="{item}" style="border:4px solid black">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ red: (item.jumlah_aktifitas_utama + item.jumlah_aktifitas_umum) == 0 }" style="height:100%;padding-top:15%">
                  {{(item.jumlah_aktifitas_utama + item.jumlah_aktifitas_umum) | formatCurrency}}
                </div>
              </td>
            </template>
            <template v-slot:item.utama_jumlah_aktivitas="{item}" style="border:4px solid black">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ red: item.jumlah_aktifitas_utama == 0 || item.jumlah_aktifitas_utama == null }" style="height:100%;padding-top:15%">
                  {{item.jumlah_aktifitas_utama == null ? 0 : item.jumlah_aktifitas_utama | formatCurrency}}
                </div>
              </td>
            </template>
            <template v-slot:item.utama_total_menit="{item}" style="border:4px solid black">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ red: item.total_aktifitas_utama == 0 || item.total_aktifitas_utama == null }" style="height:100%;padding-top:15%">
                  {{item.total_aktifitas_utama == null ? 0 : item.total_aktifitas_utama | formatCurrency}}
                </div>
              </td>
            </template>
            <template v-slot:item.tambahan_jumlah_aktivitas="{item}" style="border:4px solid black">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ red: item.jumlah_aktifitas_umum == 0 || item.jumlah_aktifitas_umum == null }" style="height:100%;padding-top:15%">
                  {{item.jumlah_aktifitas_umum == null ? 0 : item.jumlah_aktifitas_umum | formatCurrency}}
                </div>
              </td>
            </template>
            <template v-slot:item.tambahan_total_menit="{item}" style="border:4px solid black">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ red: item.total_aktifitas_umum == 0 || item.total_aktifitas_umum == null }" style="height:100%;padding-top:15%">
                  {{item.total_aktifitas_umum == null ? 0 : item.total_aktifitas_umum | formatCurrency}}
                </div>
              </td>
            </template>
            <template v-slot:item.jumlah_sudah_review="{item}" style="border:4px solid black">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ red: item.sudah_review == 0 }" style="height:100%;padding-top:15%">
                  {{item.sudah_review | formatCurrency}}
                </div>
              </td>
            </template>
            <template v-slot:item.jumlah_review_seharusnya="{item}" style="border:4px solid black">
              <td style="padding:0" align="center" class="center">
                <div v-bind:class="{ red: item.mereview == 0 }" style="height:100%;padding-top:15%">
                  {{item.mereview | formatCurrency}}
                </div>
              </td>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
// import Crud from '@/components/common/Crud'
import MonitoringAktivitasService from '@/services/MonitoringAktivitasService'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import {local} from '@/store/local'
// import ConfirmBtn from '@/components/base/ConfirmBtn'

export default {
  components:{
    // Crud,
    // LihatSKP,
    // ConfirmBtn
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {value:'nama'},
        {value:'jabatan'},
        {value:'satuan_kerja'},
        {value:'jumlah_aktivitas'},
        {value:'utama_jumlah_aktivitas'},
        {value:'utama_total_menit'},
        {value:'tambahan_jumlah_aktivitas'},
        {value:'tambahan_total_menit'},
        {value:'jumlah_sudah_review'},
        {value:'jumlah_review_seharusnya'}
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:false,
      },
      rowPerPage:0,
      page:1,
      totalKeseluruhan:0,
      selectSKPD:'',
      skpd:[],
      search: '',
      dialogLihatSKP: false,
      dataSKP: [],
      loadingLihatSKP: false,
      nilai_total_capaian: null,
      selected: {},
      tahun: null,
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      selectBulan:null
    }
  },

  computed: {
    baseUrl(){
      return process.env.VUE_APP_API_URL + '/api'
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted(){
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD, vm.selectBulan], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, bulan:this.selectBulan, kode_skpd:this.selectSKPD, search:this.search, page:this.page})
    })
  },

  created(){
    var d = new Date()
    var m = d.getMonth()+1
    this.selectBulan = m
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
    this.getListSKPD()
    this.tahun = this.$store.getters["user/data"].tahun
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []

      var params = {}
      params.kolok = this.tableValue.kode_skpd ? this.tableValue.kode_skpd : null
      params.bulan = this.tableValue.bulan ? this.tableValue.bulan : null
      MonitoringAktivitasService.getData({
        search:this.tableValue.search,
        row_per_page:this.tableValue.itemsPerPage,
        page:this.tableValue.page,
        params:params})
      .then((response)=>{
        this.items = response.data.data
        this.totalKeseluruhan = response.data.total_keseluruhan
        console.log(this.items)
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },

    getListSKPD(){
      var url = "v1/get-data-skpd2"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    checkPegawai(item){
      item.bulan = this.selectBulan
      item.month = this.selectBulan
      local.setLocal("ma_pegawai", item)
      this.$router.push({
        path: '/admin/monitoring-aktivitas/pegawai',
        component:() => import('@/views/monitoring/MonitoringAktivitasPegawai.vue'),
        params: item
      })
    },

    exportExcel(){
      var current = this.$store.getters["user/current"]
      var bulan = this.selectBulan
      var kode_skpd = this.selectSKPD
      var year = current.year
      var urls = ''
      var base = process.env.VUE_APP_API_URL
      if(base === 'https://mangbagja-demo.rev.web.id/'){
        base = 'https://erk-demo.rev.web.id/'
      }else{
        base = 'https://kinerja.bandung.go.id/'
      }
      if(!kode_skpd){
        urls = base+year+"/api/v1/monitoring-aktivitas/export?api=1&bulan="+bulan
      }else{
        urls = base+year+"/api/v1/monitoring-aktivitas/export?api=1&bulan="+bulan+"&kolok="+kode_skpd
      }
      window.open(urls,'_blank');
    }
  },
}
</script>

<style lang="scss" scoped>
.pegawai-table{
  tr,th,td {
    border: 1px solid #ddd;
  }
  thead tr th{
    color:white;
  }
}
</style>
